import styled from "styled-components";
import inPerson from "../assets/gallery/6.png";
import Mentoring from "../assets/gallery/2.jpeg";
import Remote from "../assets/gallery/10.jpg";

export const ProjectCardComponent = (props) => {
  const image =
    props.imageName === "inPerson"
      ? inPerson
      : props.imageName === "Mentoring"
      ? Mentoring
      : Remote;

  return (
    <ProjectSnippet>
      <ProjectImageContainer>
        <ProjectImage image={image} />
      </ProjectImageContainer>
      <ProjectTextContainer>
        <ProjectTitle>{props.title}</ProjectTitle>

        <ProjectDescription>{props.description}</ProjectDescription>
        <ProjectButton>{props.buttonText} </ProjectButton>
      </ProjectTextContainer>
    </ProjectSnippet>
  );
};

const ProjectTextContainer = styled.div`
  padding: 1rem 0.75rem;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 468px) {
    height: 200px;
  }
`;

const ProjectImageContainer = styled.div`
  width: 100%;
  height: 380px;

  @media screen and (max-width: 468px) {
    width: 100%;
    height: 500px;
  }
`;

const ProjectImage = styled.div`
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ProjectSnippet = styled.div`
  background-color: rgba(255, 255, 255, 0.2);

  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
  max-width: 350px;

  :hover {
    background-color: #571915;
  }

  @media screen and (max-width: 468px) {
    flex-direction: column;
    margin: 20px 0px;
  }
`;

const ProjectTitle = styled.h3`
  font-family: "Ysabeau";
  font-size: 26px;
  width: 100%;
  border-bottom: 1px solid white;
  margin: 0.5rem 0;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 2;
  }
`;

const ProjectDescription = styled.p`
  line-height: 2;
  font-size: 14px;
  font-family: "Ysabeau Office";
  font-size: 1rem;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const ProjectButton = styled.button`
  border: none;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: #fff;
  font-family: "Ysabeau";
  font-size: 16px;
  cursor: pointer;
  bottom: 20px;

  :hover {
    background-color: black;
    color: white;
  }
`;

import "../App.css";
import { HomeSection } from "../sections/HomeSection";
import { AboutSection } from "../sections/AboutSection";
import { ProjectsSection } from "../sections/ProjectsSection";
import { QuoteSection } from "../sections/QuoteSection";
import { ContactSection } from "../sections/ContactSection";
import "../styles";

import "../menu.css";
import { GallerySection } from "../sections/GallerySection";

function Home() {
  return (
    <>
      <HomeSection />
      <AboutSection />
      <QuoteSection />
      <ProjectsSection />
      <GallerySection />

      <ContactSection />
    </>
  );
}

export default Home;

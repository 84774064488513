import { React, useLayoutEffect } from "react";
import { Widget } from "@typeform/embed-react";

export const FormPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <div
        style={{
          height: "1000vh",
          marginTop: "5vh",
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <Widget
          id="kCRxRQVP"
          style={{ fontSize: "16px", width: "100%" }}
          height={800}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "200px",
          }}
        ></div>
      </div>
    </section>
  );
};

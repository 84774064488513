import styled from "styled-components";
import { SectionTitle } from "../components/SectionTitle";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PrimaryButton } from "../components/Buttons";

export const ContactSection = ({ noPaddings }) => {
  return (
    <>
      <ContactContainer noPaddings={noPaddings}>
        <div id="contact" style={{ color: "transparent" }}>
          Contact
        </div>
        <ContentContainer>
          <SectionTitle title="Apply" />

          <TextContainer noPaddings={noPaddings}>
            <ContactSubTitle>
              Are you ready to apply to serve? Well, I'll be the judge of that.
            </ContactSubTitle>

            <ContactSubTitle>Here is what you should know:</ContactSubTitle>
            <ul>
              <li>
                I only meet with carefully selected submissives whose
                applications spark my interest.
              </li>
              <li>
                By applying, you have agreed with{" "}
                <Link to="/session" style={{ textDecoration: "underline" }}>
                  My Terms of session
                </Link>
              </li>
              <li>
                The best way to impress me is with respect, transparency,
                surrender and generosity.
              </li>
              <li>
                No last-minute sessions. Book at least a few days in advance or
                don't waste your time.
              </li>
            </ul>

            <ContactSubTitle>
              Make sure you have privacy and quiet to fill in My Form. It should
              take you between 5 and 10 minutes.
            </ContactSubTitle>

            <ContactSubTitle>
              In this Form you will answer everything I need to know in order to
              create the best possible experience for the both of us: your
              contact details, availability, health status, interests and
              boundaries. Take your time and enjoy it - your submission starts
              here. Pun intended.
            </ContactSubTitle>

            <ContactSubTitle style={{ textAlign: "center" }}>
              Good luck! (...you will likely need it)
            </ContactSubTitle>

            <ContactSubTitle style={{ textAlign: "center" }}>
              <Link to="/form">
                <PrimaryButton>Begin Application, please.</PrimaryButton>
              </Link>
            </ContactSubTitle>

            <br />
            <br />
          </TextContainer>
        </ContentContainer>
      </ContactContainer>
    </>
  );
};

const ContactContainer = styled.div`
  padding: ${(props) => (props.noPaddings ? "0rem" : "8rem 8.2rem")};

  @media screen and (max-width: 500px) {
    margin: 4rem 0rem;
    padding: 0rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  padding-top: 5vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding: 0 0.5rem;
  }
`;

export const TextContainer = styled.div`
  margin: ${(props) => (props.noPaddings ? "0px" : "0px 100px")};

  @media screen and (max-width: 700px) {
    margin: 0rem 0.2rem;
  }
`;

export const ContactSubTitle = styled.p`
  font-size: 1rem;
  font-weight: 200;
  line-height: 2;
  font-family: "Kumbh Sans", sans-serif;
  @media screen and (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const SocialContainer = styled.div`
  font-size: 16px;
  padding: 1rem 0;
  color: white;
  font-family: "Kumbh Sans", sans-serif;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem 1.5rem;
  width: 250px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding: 1rem 0;
    font-size: 1rem;
    width: 300px;
  }
`;

export const AllSocialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 3rem 0rem;
  justify-content: center;

  .social-icon {
    margin-right: 1rem;
    flex-direction: column;
  }

  @media screen and (max-width: 700px) {
    height: auto;
    font-size: 1rem;

    .social-icon {
      margin-right: 0.5rem;
    }
  }
`;

export const CopyRightsFooter = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: small;
  background-color: #111;
`;

export const TextQuote = styled.div`
  font-size: 18px;
  font-style: italic;
  padding: 0rem 2rem;
`;

export const StyledLink = styled.a`
  text-decoration-color: white;
  text-decoration: none;
`;

import { React, useLayoutEffect } from "react";
import {
  ContentArea,
  InPersonSessionImage,
  IntroArea,
  IntroTextContainer,
  SessionImageContainer,
  SessionPageContainer,
  SubTitle,
} from "../components/SessionPageComponents";
import MentoringImage from "../assets/gallery/9.png";
import { SectionTitle } from "../components/SectionTitle";
import {
  ContactSubTitle,
  TextContainer,
  TextQuote,
  AllSocialsContainer,
  StyledLink,
  SocialContainer,
} from "../sections/ContactSection";
import { SiProtonmail } from "react-icons/si";

export const RemotePage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SessionPageContainer>
      <ContentArea>
        <SectionTitle title="Remote" />
        <br />
        <IntroArea>
          <SessionImageContainer>
            <InPersonSessionImage image={MentoringImage} />
          </SessionImageContainer>
          <IntroTextContainer>
            You can also serve Me online. And there are 2 ways we can go about
            it:
            <br />
            <br />
            <h3>1-on-1 Online Session</h3>
            <br />
            Video and audio on, get your accessories, I'll get mine, and let's
            have a good (and in your case potentially painful) time.
            <br />
            <br />
            <h3>Continued Distance Session</h3>
            <br />
            This is desirable for trainings of endurance, such as Chastity,
            daily tasks, feminisation, SM tasks or anything your imagination
            might come up with. It's just a matter of communication and consent.
          </IntroTextContainer>
          <br />
        </IntroArea>
        <br />
        <SubTitle>How does it work?</SubTitle>

        <TextContainer>
          1- Reach out through email telling me a bit about yourself, that you'd
          like to begin a Remote FemDom experience. Please include your time
          zone.
          <br />
          2- We will discuss the Terms of your experience and you'll be
          requested to make the first transfer.
          <br />3 - Play time!
        </TextContainer>

        <SubTitle>Examples</SubTitle>
        <TextContainer>
          Live Session:
          <ul>
            <li>Pet play, nipple torture, training, JOI</li>
            <li>Body Worship, humilation, CBT</li>
            <li>How to dress up, walk and talk like a 'proper lady'</li>
          </ul>
          Remote Domination
          <ul>
            <li>Chastity Training + Scheduled Relief</li>
            <li>"A pair of panties a day, keeps the boredom away"</li>
            <li>Body torture daily trainings</li>
          </ul>
        </TextContainer>

        <br />
        <br />
        <SectionTitle title="Contact" />
        <TextContainer>
          <ContactSubTitle>
            {" "}
            Introduce yourself properly. Let me know a bit about yourself and
            makes sure to include:
          </ContactSubTitle>
          <ul>
            <li>Your interests</li>
            <li>Your boundaries / hard limits</li>
            <li>
              Health issues that could affect the dynamic (i.e: post-surgery, a
              'bad knee', asthma etc){" "}
            </li>
            <li>How you see the dynamic unfoldings</li>
          </ul>
          <ContactSubTitle>
            Only half of the initial messages I receive are minimally
            intelligible. The others, I don't bother to reply to. Pick your
            half.
          </ContactSubTitle>
          <ContactSubTitle>
            Here is an example of an acceptable message. Feel free to use it as
            a template:
          </ContactSubTitle>
          <TextQuote>
            "Hello Mistress,
            <br /> My name is _____. I'm based in ______ (country), and I have
            some experience in BDSM such as ________. I'd love to discuss a
            remote dynamic involving ________. My limits are _______.
            <br />
            Looking forward to hearing back from you."
          </TextQuote>
          <br />

          <AllSocialsContainer>
            <StyledLink href="https://mailto:mayamistress@protonmail.com">
              <SocialContainer>
                <SiProtonmail className="social-icon" />
                Contat Me via Email
              </SocialContainer>
            </StyledLink>
          </AllSocialsContainer>

          <br />
          <br />
          <br />
        </TextContainer>
      </ContentArea>
    </SessionPageContainer>
  );
};

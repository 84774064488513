import styled from "styled-components";
import { SectionTitle } from "../components/SectionTitle";
import { TimedSlider } from "../components/TimedSlider";

export const QuoteSection = () => {
  return (
    <QuoteWrapper>
      <QuoteContainer>
        <SectionTitle title="Testimonials" centered />
        <br />
        <TimedSlider />
      </QuoteContainer>
    </QuoteWrapper>
  );
};

const QuoteWrapper = styled.div`
  min-height: 75vh;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    display: flex;
    padding: 4rem 1rem;
    justify-content: center;
  }
`;

const QuoteContainer = styled.div`
  margin: 4rem 4.1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4rem;
  border-radius: 16px;

  @media screen and (max-width: 600px) {
    padding: 2rem 1rem;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
  }
`;

export const QuoteText = styled.p`
  font-size: 18px;
  line-height: 2;
  font-style: italic;
  font-family: "Ysabeau", sans-serif;

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    font-size: 16px;
    line-height: 1.5;
    padding: 1.2rem;
  }
`;

export const QuoteAuthor = styled.p`
  align-self: end;
  font-family: "Ysabeau", sans-serif;
  line-height: 2;
  text-align: right;
  margin-bottom: 3rem;
  @media screen and (max-width: 600px) {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

import React from "react";
import styled from "styled-components";
import { SectionContainer } from "../components/SectionContainer";
import { SectionTitle } from "../components/SectionTitle";

import { ProjectCardComponent } from "../components/ProjectCardComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const ProjectsSection = () => {
  return (
    <ProjectsContainer>
      <div id="projects" style={{ color: "transparent" }}>
        Projects
      </div>
      <SectionContainer>
        <SectionTitle title="Experiences" />
        <ExperiencesContainer>
          <Link to="/sessions">
            <ProjectCardComponent
              title="In-Person Sessions"
              description="Would you dare to fully surrender to a powerful woman? I invite you to experience Female Domination at its finest."
              imageName="inPerson"
              buttonText="Yes, Mistress!"
            />
          </Link>

          <Link to="/mentoring">
            <ProjectCardComponent
              title="Mentoring"
              description="A non-D/s encounter or call where I can help you explore the misunderstood - yet undeniable - beauty of BDSM."
              imageName="Mentoring"
              buttonText="I'm ready"
            />
          </Link>

          <Link to="/remote">
            <ProjectCardComponent
              title="Remote Domination"
              description="Online sessions, continued Domination via tasks, remote trainings and much more. The best way to build a continued connection."
              imageName="Remote"
              buttonText="Yes, please!"
            />
          </Link>
        </ExperiencesContainer>
      </SectionContainer>
    </ProjectsContainer>
  );
};

const ProjectsContainer = styled.div`
  background: black;
  width: 100%;
`;

const ExperiencesContainer = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 60px auto;
  gap: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

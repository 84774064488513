import { createGlobalStyle } from "styled-components";

import Scriptina from './assets/scriptina.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Scriptina';
        font-weight: normal;
        font-style: normal;
        font-display: swap;
        src: url(${Scriptina}) format('truetype');
    }
`

export default GlobalStyle
import { React, useLayoutEffect } from "react";
import { SectionTitle } from "../components/SectionTitle";
import SessionImage from "../assets/gallery/6.png";
import { SessionAccordions } from "../components/Accordions";
import { ContactSection } from "../sections/ContactSection";

import { FaCheck, FaX } from "react-icons/fa6";

import {
  SessionPageContainer,
  InPersonSessionImage,
  IntroArea,
  IntroTextContainer,
  SessionImageContainer,
  ContentArea,
  TextContainer,
  SubTitle,
  StepTitle,
  StepDescription,
  TributesWrapper,
  BiggerText,
  TertiaryTitle,
  IncludedWrapper,
  TableContainer,
} from "../components/SessionPageComponents";

export const InPersonSession = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SessionPageContainer>
      <ContentArea>
        <SectionTitle title="Sessions" />
        <br />
        <IntroArea>
          <SessionImageContainer>
            <InPersonSessionImage image={SessionImage} />
          </SessionImageContainer>
          <IntroTextContainer>
            Before we go any further, let’s be explicit of what I mean. I am
            extremely selective of my submissives, and I strive to offer a
            mesmerizing experience you will never forget. That means that not
            only I have very limited time slots per month, but also means that
            sessions must be scheduled in advance. So please contact me around a
            week in advance from your desired session date. A 'late-night' or
            'kinky-bootycall' isn't what I'm here for. The mere idea of it,
            makes me laugh.
            <br /> <br />
            Supposing you are selected to serve Me, in-session, you will submit
            to Me in any ways or forms that I command. You will be my little
            play toy, my bitch, my servant. Once I decide what to do with you,
            there is nothing you can do about it but to endure it, enjoy it - or
            call for the safe word if something’s wrong.
            <br /> <br />
            No session is like the other, my approach is not one of scripts or
            tricks. It is a personal connection, as deep as you consent to,
            where I will explore your mind and body, discover what makes you
            tick, what makes you cry, what makes you smile… And use it at My
            pleasure.
            <br /> <br />
            Some experiences are beyond classification. But here are some
            teasers for your imagination:
          </IntroTextContainer>
        </IntroArea>
        <br />
        <SessionAccordions />
        <br />
        <br />
        <SubTitle>In Practical Terms...</SubTitle>

        <TertiaryTitle>What to expect</TertiaryTitle>
        <BiggerText>
          <FaCheck /> All your <strong>boundaries and limits</strong> respected
        </BiggerText>
        <BiggerText>
          <FaCheck /> A Mistress who is truly passionate about Fetish, BDSM,
          FemDom and Power Dyanmics.
        </BiggerText>
        <BiggerText>
          <FaCheck /> I don't work with scripts (unless in the context of a role
          play). I make preparations and plans, but during the session, I go
          with the flow, and do what feels good for me, and right for your
          training.
        </BiggerText>
        <BiggerText>
          <FaCheck /> <strong>Safewords</strong>: You will receive safewords
          which you can call at any time of the session. They will signal to Me
          something that is a potential boundary, and some other important
          details mid-session.
        </BiggerText>
        <BiggerText>
          <FaCheck /> I strive to provide a
          <strong> Premium experience in FemDom</strong> for anyone who books
          me. Politeness, discretion and a touch of formality are some of the
          values I carry myself as a professional. You will have access to
          top-notch skills, high-quality accessories, attention to hygiene and
          all other details that make for a smooth and memorable experience.
        </BiggerText>
        <BiggerText>
          <FaCheck /> A <strong>personal touch </strong>is also always present
          in dynamics with Me. I prefer to cultivate long-term relationships,
          than have a more 'one-off', 'transactional' approach. And I select
          submissives with the same mindset.
        </BiggerText>
        <BiggerText>
          <FaCheck /> <strong>Aftercare :)</strong> Once our session is done,
          the last minutes will be reserved for 'cool down', talk to each other,
          check in on how each other feel, just to make sure we are both
          'grounded' before venturing back to the 'outside world'.
        </BiggerText>

        <TertiaryTitle>What NOT to expect:</TertiaryTitle>
        <BiggerText>
          <FaX /> Don't feel entitled to an Orgasm: yes, it is frequent that
          submissives orgasm during sessions. However this will not be the focus
          of the session. If that happens, it should be seen as gift for good
          behaviour, not as a box to be 'checked'.
        </BiggerText>
        <BiggerText>
          <FaX /> Sex. If you expect sex from a session with a Dominatrix, you
          must be widly misinformed. With that said, during sessions as your
          Mistress, if I feel like using you, I will - unless of course you list
          that as a hard limit. I am entitled to your body, mind and soul. You
          are entitled to nothing but my time, and having your boundaries
          respected. If that doesn't work for you, we are not a match.
        </BiggerText>
        <BiggerText>
          <FaX /> Intoxication: please refrain from alcohol use, weed,
          psychodelics, or any other non-prescribed substances prior to our
          session. It's okay to have a glass of wine after the session while we
          talk, but it is important to be sober during the experience. There are
          several reasons for this, which I can gladly share in out intro phone
          call if needed.
        </BiggerText>
        <BiggerText>
          <FaX /> Any disrespect to my boundaries, unwelcomed touching or pervy
          comments are not going to be tolerated. If you make me feel
          uncomfortable or disrepect my boundaries, I will leave and take my
          tribute with me.
        </BiggerText>
        <BiggerText>
          <FaX /> No last-minute sessions. I'm not 'on call'. My appointments
          are scheduled, confirmed, and very well-prepared.
        </BiggerText>
        <BiggerText>
          <FaX /> No late-night sessions [between 10pm and 11am], unless for
          overnights, scheduled in advance.
        </BiggerText>
        <br />
        <br />
        <TributesWrapper>
          <SubTitle>Tributes</SubTitle>
          <IncludedWrapper>
            <div>
              <TertiaryTitle>What IS included:</TertiaryTitle>
              <BiggerText>
                <FaCheck /> All session practices: no mid-session fees or
                "surprise fees"
              </BiggerText>
              <BiggerText>
                <FaCheck /> Any transportations costs within Amsterdam: no "taxi
                fees"
              </BiggerText>
              <BiggerText>
                <FaCheck /> The use of all sex toys and accessories - brought by
                Me to the session, hand-picked from my extensive collection
              </BiggerText>
              <BiggerText>
                <FaCheck /> One intro audio call prior to our first session to
                get to know each other a bit, discuss interests, boundaries, and
                clarify any questions.
              </BiggerText>
            </div>
            <div>
              <TertiaryTitle>What is NOT included:</TertiaryTitle>
              <BiggerText>
                <FaX /> Hotel/Studio costs: long story short - it's a legal
                thing within The Netherlands. By booking the room yourself, we
                are 100% compliant.
              </BiggerText>
              <BiggerText>
                <FaX /> Couples/Duos: please consult Me on Tributes for this
                case.
              </BiggerText>
            </div>
          </IncludedWrapper>
          <br />
          <TableContainer>
            <table>
              <tbody>
                <tr>
                  <td>2h</td>
                  <td>600 €</td>
                </tr>
                <tr>
                  <td>3h</td>
                  <td>900 €</td>
                </tr>
                <tr>
                  <td>6h</td>
                  <td>1600 €</td>
                </tr>

                <tr>
                  <td>16h</td>
                  <td>2500 €</td>
                </tr>
                <tr>
                  <td>24h</td>
                  <td>3000 €</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <br />
        </TributesWrapper>

        <SubTitle>Admission Process</SubTitle>

        <TextContainer>
          <StepTitle>1 - Fill in My Application Form</StepTitle>
          <StepDescription>
            Reserve a few minutes to fill in The Mistress's Form I prepared for
            you. I designed it in a way to make for a pleasurable and insightful
            step. It will inform me of everything I need to prepare a memorable
            session as well as your contact details. I will contact you back
            within 3 business days at max with my decision.
          </StepDescription>
          <StepTitle>
            2 - If you're approved, send a deposit to lock in your time
          </StepTitle>
          <StepDescription>
            If you have been approved, and schedules are match, you will be
            required to send a small deposit to lock in your time. This is a
            protocol to avoid time wasters and no-shows, and standard in the
            industry. If you need to reschedule or cancel your session, you
            don't get a refund. In the eventually of something happening and I
            must reschedule, your deposit may be returned.
            <StepTitle>3 - Audio Call </StepTitle>
            <StepDescription>
              we will schedule a quick call to 'break the ice'. That helps me to
              assess our chemesty, to understand you and your tendencies better.
              This call is free of cost and is about the session. For further
              online contact, I request we schedule a proper online sesison.
            </StepDescription>
          </StepDescription>
          <StepTitle>4 - Play time!</StepTitle>
          <StepDescription>
            We will start our time together in a casual way, having a drink and
            talking about expectations (which is how usually kinky people
            'flirt', so to say...). Once the session starts however, you'll be
            very quiet... Until you aren't. But oh well, you won't be 'talking'
            either. <br />
            The session will end with a nice wind down, a nice casual chat, and
            some after care. Feel free to inform me if you have any after care
            needs.
          </StepDescription>
          <StepTitle>5 - Post-session check-in</StepTitle>
          <StepDescription>
            It's always nice to check in the next day, see if everyone is
            feeling well. Sub or Top Drops are possible, so it's only nice to
            send a quick message and make sure everyone is okay and happy with
            the session.
          </StepDescription>
        </TextContainer>
        <br />
        <SubTitle>Do you Dare to Surrender?</SubTitle>

        <ContactSection noPaddings={true} />
      </ContentArea>
    </SessionPageContainer>
  );
};

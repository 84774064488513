import React from "react";
import styled from "styled-components";
import { SectionContainer } from "../components/SectionContainer";
import { SectionTitle } from "../components/SectionTitle";
import AboutImage from "../assets/gallery/about.jpg";

export const AboutSection = () => {
  return (
    <>
      <div id="about" style={{ color: "transparent" }}>
        About
      </div>
      <SectionContainer background={"black"} center>
        <AboutContent>
          <SectionTitle title="About" mobileOnly />
          <ImageContainer />
          <AboutText>
            <SectionTitle
              title="About"
              hideOnMobile
              style={{ marginTop: "-50px" }}
            />
            <QuoteStyleTextWrapper>
              <QuoteStyleText>Sensual, yet sadistic. </QuoteStyleText>
              <QuoteStyleText>Playful, yet merciless. </QuoteStyleText>
              <QuoteStyleText> Spicy, yet sweet.</QuoteStyleText>
            </QuoteStyleTextWrapper>
            Female Domination can be experienced as a fetish... Or as something
            more. My personal journey with Power Dynamics started during my
            University studies, when I published a Thesis on the subject. Once I
            added autonomous sexuality and FemDom to my personal experiments
            with power dynamics, I was forever transformed - and I never looked
            back.
            <br /> For years I was a full-time Pro Domme in my home country,
            where fostered a beautiful clan of faithful slaves and became one of
            the most well-tributed Pro Dommes in South America. But my ambition
            didn't stop there. That's when I came to Europe to expand my
            horizons.
            <br />
            Nowadays I run a business, which has been a fruitful and passionate
            journey and, in parallel, I offer FemDom sessions for hand-picked
            individuals - for my own pleasure and self-expression. This approach
            allows me focus only the best applicants, and prepare a truly
            mesmerizing experience - for the both of us.
          </AboutText>
        </AboutContent>
      </SectionContainer>
    </>
  );
};

const AboutContent = styled.article`
  line-height: 2;
  margin-bottom: 4rem;
  display: flex;
  gap: 28px;
  max-width: 1200px;
  font-size: 18px;
  line-height: 2;

  @media screen and (max-width: 468px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 35%;
  height: 80vh;
  background-image: url(${AboutImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 468px) {
    width: 100%;
  }
`;

const AboutText = styled.div`
  width: 65%;

  @media screen and (max-width: 468px) {
    width: 100%;
    font-size: 18px;
  }
`;

const QuoteStyleTextWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const QuoteStyleText = styled.span`
  font-style: italic;
  text-align: center;
  font-size: 24px;
  margin-right: 5px;
`;

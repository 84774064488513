import styled from "styled-components";

export const SecondartButton = styled.a`
  width: 100%;
  background-color: #222;
  color: white;
  display: block;
  text-align: center;
  border: 1px solid white;
  padding: 0.5rem 1rem;
`;

export const PrimaryButton = styled.button`
  border: none;
  padding: 0.5rem 1rem;
  width: 280px;
  background-color: #fff;
  font-family: "Ysabeau";
  font-size: 16px;
  cursor: pointer;
  bottom: 20px;

  :hover {
    background-color: #222;
    color: white;
  }
`;

import styled from "styled-components";
import { QuoteAuthor, QuoteText } from "../sections/QuoteSection";
import { Testimonials } from "../data/Testimonials";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const QuoteArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 30px;

  @media screen and (max-width: 500px) {
    margin: auto;
  }
`;

export const TimedSlider = ({ children }) => {
  return (
    <Carousel showThumbs={false}>
      <QuoteArea>
        <QuoteText>{Testimonials[0].description}</QuoteText>
        <QuoteAuthor>
          - {Testimonials[0].author}, <br />
          {Testimonials[0].title}
        </QuoteAuthor>
      </QuoteArea>
      <QuoteArea>
        <QuoteText>{Testimonials[1].description}</QuoteText>
        <QuoteAuthor>
          - {Testimonials[1].author}, <br />
          {Testimonials[1].title}
        </QuoteAuthor>
      </QuoteArea>
      <QuoteArea>
        <QuoteText>{Testimonials[2].description}</QuoteText>
        <QuoteAuthor>
          - {Testimonials[2].author}, <br />
          {Testimonials[2].title}
        </QuoteAuthor>
      </QuoteArea>
    </Carousel>
  );
};

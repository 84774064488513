import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

export const SessionAccordions = () => {
  return (
    <>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>High Protocol Training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Training you into the each and every formality of My protocol. It
              can be mixed with other types of approaches. Mandatory if we are
              to make it into BDSM encounters together.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Punching-bag Training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              A full-on sadistic / masochist dynamic where I’ll just beat the
              shit out of you, and train you into taking more and more pain each
              time.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Feminisation</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              wether you want to be a lady or a whore, I can bring it out of
              you. How far can you go?
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Service-oriented submission
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              make yourself useful to Me. Shine My boots, clean My shoes, wash
              My lingerie, clean My house, be My secretary… So many tasks I can
              delegate for the right hands… What is your talent (if you have
              any)? Just to be clear: this is not about a role-play of being
              useful. You won’t be ‘pretending’ to work. You will be doing
              actual work. And I will demand excellence.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Basic Submission training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Not everyone can submit and explore multiple trainings right away.
              Some need first to understand who is in charge. And that is not
              something we do with words, but with actions. The body has to feel
              it, the body has to know it. And this takes time.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Bondage-focused</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              I love ropes and restriction play in general. For this training
              I’ll spend the most part tying you up in quite a serious way so
              you have no escape. And then I’ll play mind - and body - games
              with you. Or not. Maybe I just leave you there, alone and tied
              down, in silence, for long minutes… Who knows?
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Worship training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Nothing like the feeling of devotion to elevate the soul. In this
              experience I’ll be a cruel (or not) Goddess you can worship. We
              can make this a full ritual, where you lay flowers by My feet,
              with wine and candles… Or we can make it kinky as hell, and you’ll
              be grateful that this Goddess has walked all over your back.
              Evoking devotion is an art on its own, and I love every part of
              it.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Edging training </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Oh, the sweet despair of being so many times close to orgasm and
              not achieving it… The torture… And if I were you I wouldn’t dare
              to make a mistake… I have ZERO mercy when punishing idiots who
              can’t control their own dick.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Humiliation training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This one can be especially intense for the mind, especially on
              hard core modes. We’ll focus on proving to yourself the piece of
              shit that you are, and treating you like such.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Pet play training</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              A puppy or a kitty, it doesn’t matter. I have a collar, a bowl and
              lots of treats and Discipline methods for you. The most important
              question is: are you a good little bitch or are you a bad little
              bitch?
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Brat-taming</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Oh, the world of BDSM wouldn’t be the same without you little
              Brats. Anti-authority, testing limits, trying to annoy the hell
              out of the Dominants… I get it, I get it… I accept the challenge.
              And I understood the assignment.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Hedonistic approach</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              For those who “just” want to submit and enjoy the deliciousness of
              the experience, not really caring about developing a more serious
              training, high protocol, or any focused trainings. As long as you
              know your place, and submit, I’m game. And if you dare to test me,
              you’ll get a good brat-taming dose.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

import React from "react";
import styled from "styled-components";

export const SectionTitle = ({ title, mobileOnly, hideOnMobile, centered }) => {
  return (
    <Container mobileOnly={mobileOnly} hideOnMobile={hideOnMobile}>
      <CursiveTitleContainer>
        <CursiveTitle centered={centered}>{title}</CursiveTitle>
      </CursiveTitleContainer>
      <MainTitle>{title}</MainTitle>
    </Container>
  );
};

const Container = styled.div`
  display: ${(props) => (props.mobileOnly ? "none" : "block")};
  margin-top: 3rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    display: ${(props) => (props.hideOnMobile ? "none" : "block")};
  }
`;

const CursiveTitleContainer = styled.div`
  position: relative;
`;

const CursiveTitle = styled.h1`
  font-family: "Scriptina";
  font-weight: 200;
  opacity: 0.4;
  position: absolute;
  font-size: 72px;
  letter-spacing: 14px;
  margin-bottom: 1rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);

  @media screen and (max-width: 600px) {
    font-size: 3rem;
    letter-spacing: 4px;
    margin-top: -5rem;

    top: -20px;
    bottom: 0;
    margin: auto 0;
    transform: translate(-50%, -150%);
  }
`;

const MainTitle = styled.p`
  text-transform: uppercase;
  color: white;
  font-size: 36px;
  font-weight: 300;
  margin-top: 0rem;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const Testimonials = [
  {
    author: "Electra_Cute",
    title: "Maya Mistress's little queer prey",
    description:
      "Mistress Maya has a natural primal energy that takes a hold of you the moment you meet her. I remember being impressed by her elegance and her gaze. For me BDSM has always been a very mental activity. With Mistress Maya I knew instinctively that I was dealing with a very intelligent and powerful woman doesn’t just act on the kinks and wishes of the submissive. Mistress Maya will find out what makes you tick, take you apart and build you back up. Mistress Maya is very capable and professional in creating a safe space where a dynamic can grow. It’s an intoxicating energy Mistress Maya fills me with. Even after our sessions she still lingers in my thoughts. I can still feel the rope around my hands and my head being pulled up by my hair. And her breath and smell as she whispers “good girl” in my ear. And I still feel the marks she made on my body, claiming me and reminding me I am hers. And most exhilarating of it all, I can never predict where Mistress Maya will take me next time.",
  },
  {
    author: "A.J.",
    title: "Former house-cleaner slave",
    description:
      "I'll admit the first time I met Mistress Maya I was a bit nervous. We had set up my boundaries, limits and overall desires beforehand. In this conversation, Mistress took Her time to get to know me and to see in which direction our dynamic would go. So the first time coming over to serve Her I felt safe under her power and my nerves quickly disappeared. Mistress immediately made me kneel and crawl toward Her. Feeling her power and seeing Her beauty I fell into deep submission, wanting to serve and work hard to make Mistress happy. Mistress Maya is strict and makes it clear what She expects of you which is nothing less than perfection. If not, Mistress does not hesitate to punish you strictly with one of Her fiercely whips and She can be very creative in punishing you as She has loads of experience with slaves. Going home after serving Mistress I was replaying the session in my head. The humiliating things she said made me feel small as my dick pressed hard against my chastity cage. And to receive a reward after beeing a good boy made me feel like a proud slave. I'm happily owned by Mistress Maya and feel privileged to call myself Her slave.",
  },
  {
    author: "Wheel Boy",
    title: "Maya Mistress's Personal Chaffeur",
    description:
      "I have the pleasure of serving Maya Mistress as Her personal chauffeur since March 2021. It is an absolute pleasure to serve Her. Her high standards of protocol and etiquette She demands from my work for Her makes Her life much easier and so much more luxurious.",
  },
];

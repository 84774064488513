import { useState } from "react";
import styled from "styled-components";
import { SectionTitle } from "../components/SectionTitle";

import imageAss from "../assets/gallery/1.png";
import image2 from "../assets/gallery/2.jpeg";
import image3 from "../assets/gallery/3.png";
import image4 from "../assets/gallery/4.jpg";
import image5 from "../assets/gallery/5.jpg";
import image6 from "../assets/gallery/6.png";
import image7 from "../assets/gallery/7.jpeg";
import image8 from "../assets/gallery/8.jpg";
import image9 from "../assets/gallery/9.png";
import image10 from "../assets/gallery/10.jpg";
import about from "../assets/gallery/about.jpg";
import LeatherBootsAndCrop from "../assets/gallery/leatherBootsAndCrop.jpeg";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import { Fullscreen, Slideshow } from "yet-another-react-lightbox/plugins";

const photos = [
  {
    id: LeatherBootsAndCrop,
    src: LeatherBootsAndCrop,
    width: 300,
    height: 350,
  },
  {
    id: "imageAss",
    src: imageAss,
    width: 350,
    height: 300,
  },
  {
    id: "about",
    src: about,
    width: 300,
    height: 410,
  },
  {
    id: "strapon",
    src: image3,
    width: 300,
    height: 265,
  },
  {
    id: "redboots",
    src: image4,
    width: 300,
    height: 400,
  },
  {
    id: "accessories",
    src: image5,
    width: 400,
    height: 300,
  },

  {
    id: "you",
    src: image6,
    width: 400,
    height: 550,
  },
  {
    id: "blackcorset",
    src: image7,
    width: 300,
    height: 400,
  },
  {
    id: "chairchampagne",
    src: image8,
    width: 400,
    height: 300,
  },
  {
    id: "cuffsWhip",
    src: image9,
    with: 300,
    height: 400,
  },
  {
    id: "corsetseated",
    src: image10,
    width: 300,
    height: 400,
  },
  {
    id: "boots",
    src: image2,
    width: 300,
    height: 400,
  },
];

export const GallerySection = () => {
  const [index, setIndex] = useState(-1);

  const GalleryContainer = styled.div`
    background-color: black;
    padding: 5rem 2rem;
    margin-top: -5rem;

    @media screen and (max-width: 500px) {
      padding: 1rem 0.5rem;
    }
  `;

  return (
    <>
      <GalleryContainer>
        <SectionTitle title="Gallery" />
        <br />
        <br />
        <PhotoAlbum
          photos={photos}
          layout="masonry"
          columns={(containerWidth) => {
            if (containerWidth < 500) return 2;
          }}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          open={index >= 0}
          close={() => setIndex(-1)}
          slides={photos}
          index={index}
          plugins={[Fullscreen, Slideshow]}
        />
      </GalleryContainer>
    </>
  );
};
